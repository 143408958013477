










































































































































































































































































































































































































































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import store, { useUserState, useUserActions, useUserGetters } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import axios from 'axios';

export default defineComponent({
  name: 'MProgramSettings',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    programId: {
      type: String,
      required: true
    },
    adkData: {
      required: false,
      default: ''
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any, ctx: any) {
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { user }: any = useUserState(['user']);
    const { saveActivitySettings } = useUserActions(['saveActivitySettings']);
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programIdValue = computed({
      get: () => props.programId,
      set: newVal => {
        console.log(newVal);
      }
    });

    const adkData = computed(() => {
      return props.adkData;
    });
    const activityNameValue = computed(() => {
      return adkData.value?.name || '';
    });
    const theme: any = ref('');
    const themes = ref([
      { value: 'Student Green', color: '#6eba80', checked: false },
      { value: 'Teacher Pink', color: '#eda1bf', checked: false },
      { value: 'Employer Purple', color: '#ae90b0', checked: false },
      { value: 'School Blue', color: '#3c9dcd', checked: false },
      { value: 'Parent Yellow', color: '#fec34b', checked: false },
      { value: 'Sponsor Red', color: '#ea6764', checked: false },
      { value: 'Sibling Orange', color: '#f79961', checked: false },
      { value: 'Template Black', color: '#000000', checked: false }
    ]);
    const programTemplates = ref([
      { value: 'Flagship - Asynchronous Student Interaction Template', checked: false },
      { value: 'Flagship - Zero Management Template', checked: false }
    ]);
    const resourceTypes = ref([
      { value: 'Time', checked: false },
      { value: 'Money', checked: false },
      { value: 'Information', checked: false },
      { value: 'Technology', checked: false },
      { value: 'Physical', checked: false }
    ]);
    const activityRequired = ref('');
    const requirements = ref('');
    const requirementOptions = ref([]);
    const requirementSearch = ref(null);
    const setupRequired = computed(() => {
      return adkData.value?.setupRequired ? 'yes' : 'no';
    });

    const iconName = computed(() => {
      return adkData.value?.icon || '';
    });
    const activityGroups = ref([
      { value: 'Project', checked: false },
      { value: 'Internship', checked: false }
    ]);
    const activityPlacement = ref('');
    const isDeliverable = ref('');
    const outcomes = ref('');
    const instructions = ref('');
    const reminder = ref('');
    const CredentialListItems = ref(['Internship', 'Externship', 'Sponsor']);
    const activities = ref(['Start', 'Youtube Shorts', 'Result', 'End']);
    const credentials = ref([]);
    const rewards = ref([]);

    const activityView = ref([
      { value: 'Allow view only', checked: false },
      {
        value: 'Allow view, comment and feedback between stakeholder and participant',
        checked: false
      },
      {
        value: 'Do not allow view, comment or feedback between stakeholder and participant',
        checked: false
      },
      { value: 'Allow organizer to decide', checked: false }
    ]);
    const postActivityReflection = ref([
      { value: 'Enable post-activity reflection for stakeholder view', checked: false },
      { value: 'Enter question for subjective reply', checked: false },
      {
        value: 'Allow organizer to decide to enable (with provided default question)',
        checked: false
      },
      { value: 'Enter question for subjective reply if organizer enables', checked: false },
      {
        value:
          'Allow organizer to decide to enable and change question (with provided default question)',
        checked: false
      },
      { value: 'Enter default question', checked: false },
      { value: 'Disable post-activity reflection for stakeholder view', checked: false }
    ]);
    const makeActivityAccessible = ref([
      { value: 'Do not make accessible anytime', checked: false },
      { value: 'Make accessible anytime', checked: false },
      { value: 'Allow organizer to decide', checked: false }
    ]);
    const allowToEndProgramEarly = ref([
      { value: 'Do not allow', checked: false },
      { value: 'Allow only at preset order in program template', checked: false },
      { value: 'Allow organizer to decide', checked: false }
    ]);
    const merits = ref([
      { value: 'Internship Credential', checked: false },
      { value: 'Externship', checked: false }
    ]);
    const typeOfCharts = ref([
      { value: 'Preset Levels', checked: false },
      { value: 'Preset vs. Preset Comparison', checked: false },
      { value: 'Preset / Setup Ratio', checked: false }
    ]);
    const presetAndSetup = ref([
      { value: 'Preset option 1', checked: false },
      { value: 'Preset option 2', checked: false },
      { value: 'Preset option 3', checked: false },
      { value: 'Setup item 1', checked: false },
      { value: 'Setup item 2', checked: false },
      { value: 'Setup item 3', checked: false }
    ]);
    const mongoDbChart = ref('');
    const participantAndTeamData = ref([
      { value: 'View outcomes by participants', checked: false },
      { value: 'View outcomes by teams', checked: false },
      { value: 'View outcomes by selected groups', checked: false }
    ]);
    const isLoading = ref(false);
    const idealTime = ref();
    // const reminderTime = ref();
    const timeIn = ref('Hours');
    const timeOptions = ref(['Hours', 'Days']);
    const idealActivityCompletionTime = ref();
    const timeInForCountDown = ref('Minutes');
    const timeOptionsForCountDown = ref(['Minutes', 'Hours']);
    const isPreview = ref();
    const timerType = ref();
    const isShowFeedBackForm = ref();
    const checkTimeToSolveProblem = ref();
    const timeToSolveProblem = ref();

    const getActivitySettings = async () => {
      const programDoc = getProgramDoc?.value;
      const activity = programDoc?.adks[props?.adkIndex];
      isPreview.value = activity?.preview;
      theme.value = themes?.value?.find(t => t?.color === activity?.styling?.borderColor)?.value;
      const activitySettingsData = activity?.activitySettings;

      if (activitySettingsData) {
        programTemplates.value?.forEach((val, index) => {
          if (activitySettingsData?.programTemplates?.includes(val?.value)) {
            if (programTemplates.value[index]) {
              programTemplates.value[index].checked = true;
            }
          }
        });
        resourceTypes.value?.forEach((val, index) => {
          if (activitySettingsData?.resourceTypes?.includes(val?.value)) {
            if (resourceTypes.value[index]) {
              resourceTypes.value[index].checked = true;
            }
          }
        });
        requirements.value = activitySettingsData?.requirements;
        activityRequired.value = activitySettingsData?.activityRequired;
        activityGroups.value?.forEach((val, index) => {
          if (activitySettingsData?.activityGroups?.includes(val?.value)) {
            if (activityGroups.value[index]) {
              activityGroups.value[index].checked = true;
            }
          }
        });
        activityPlacement.value = activitySettingsData?.activityPlacement;
        isDeliverable.value = activitySettingsData?.isDeliverable;
        outcomes.value = activitySettingsData?.outcomes;
        instructions.value = activitySettingsData?.instructions;
        reminder.value = activitySettingsData?.reminder;
        activityView.value?.forEach((val, index) => {
          if (activitySettingsData?.activityView?.includes(val?.value)) {
            if (activityView.value[index]) {
              activityView.value[index].checked = true;
            }
          }
        });
        postActivityReflection.value?.forEach((val, index) => {
          if (activitySettingsData?.postActivityReflection?.includes(val?.value)) {
            if (postActivityReflection.value[index]) {
              postActivityReflection.value[index].checked = true;
            }
          }
        });
        makeActivityAccessible.value?.forEach((val, index) => {
          if (activitySettingsData?.makeActivityAccessible?.includes(val?.value)) {
            if (makeActivityAccessible.value[index]) {
              makeActivityAccessible.value[index].checked = true;
            }
          }
        });
        allowToEndProgramEarly.value?.forEach((val, index) => {
          if (activitySettingsData?.allowToEndProgramEarly?.includes(val?.value)) {
            if (allowToEndProgramEarly.value[index]) {
              allowToEndProgramEarly.value[index].checked = true;
            }
          }
        });
        merits.value?.forEach((val, index) => {
          if (activitySettingsData?.merits?.includes(val?.value)) {
            if (merits.value[index]) {
              merits.value[index].checked = true;
            }
          }
        });
        typeOfCharts.value?.forEach((val, index) => {
          if (activitySettingsData?.typeOfCharts?.includes(val?.value)) {
            if (typeOfCharts.value[index]) {
              typeOfCharts.value[index].checked = true;
            }
          }
        });
        presetAndSetup.value?.forEach((val, index) => {
          if (activitySettingsData?.presetAndSetup?.includes(val?.value)) {
            if (presetAndSetup.value[index]) {
              presetAndSetup.value[index].checked = true;
            }
          }
        });
        mongoDbChart.value = activitySettingsData?.mongoDbChart;
        participantAndTeamData.value?.forEach((val, index) => {
          if (activitySettingsData?.participantAndTeamData?.includes(val?.value)) {
            if (participantAndTeamData.value[index]) {
              participantAndTeamData.value[index].checked = true;
            }
          }
        });
        idealTime.value = activitySettingsData?.idealTime;
      }
      // console.log('activity', activity);
      // console.log('pogramDoc', pogramDoc);
      if (activity?.credentials) {
        credentials.value = activity?.credentials;
      }
      if (activity?.rewards) {
        rewards.value = activity?.rewards;
      }
      if (programDoc?.timerType) {
        timerType.value = programDoc?.timerType;
        if (timerType?.value === 'Count Down' && activity?.idealActivityCompletionTime) {
          idealActivityCompletionTime.value = activity?.idealActivityCompletionTime;
        } else if (timerType?.value === 'Count Down' && activity?.timeInForCountDown) {
          timeInForCountDown.value = activity?.timeInForCountDown;
        }
      }
      if (activity?.isShowFeedBackForm) {
        isShowFeedBackForm.value = activity?.isShowFeedBackForm;
      }
      if (activity?.timeToSolveProblem) {
        checkTimeToSolveProblem.value = activity?.timeToSolveProblem;
        timeToSolveProblem.value = activity?.timeToSolveProblem;
      }
    };

    getActivitySettings();

    const saveActivitySettingsData = async () => {
      isLoading.value = true;
      const payload: any = {
        activityName: activityNameValue?.value,
        iconName: iconName?.value,
        theme: themes?.value?.find(t => t?.value === theme?.value)?.color,
        programTemplates: programTemplates?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        setupRequired: setupRequired?.value,
        resourceTypes: resourceTypes?.value?.filter(item => item?.checked).map(item => item?.value),
        requirements: requirements?.value,
        activityRequired: activityRequired?.value,
        activityGroups: activityGroups?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        activityPlacement: activityPlacement?.value,
        isDeliverable: isDeliverable?.value,
        outcomes: outcomes?.value,
        instructions: instructions?.value,
        reminder: reminder?.value,
        activityView: activityView?.value?.filter(item => item?.checked).map(item => item?.value),
        postActivityReflection: postActivityReflection?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        makeActivityAccessible: makeActivityAccessible?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        allowToEndProgramEarly: allowToEndProgramEarly?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        merits: merits?.value?.filter(item => item?.checked).map(item => item?.value),
        typeOfCharts: typeOfCharts?.value?.filter(item => item?.checked).map(item => item?.value),
        presetAndSetup: presetAndSetup?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        mongoDbChart: mongoDbChart?.value,
        participantAndTeamData: participantAndTeamData?.value
          ?.filter(item => item?.checked)
          .map(item => item?.value),
        idealTime: idealTime?.value,
        credentials: credentials.value,
        rewards: rewards.value,
        preview: isPreview?.value,
        timeInForCountDown: timeInForCountDown?.value,
        idealActivityCompletionTime: idealActivityCompletionTime?.value,
        isShowFeedBackForm: isShowFeedBackForm?.value
      };
      if (timeToSolveProblem?.value) {
        payload.timeToSolveProblem = Number(timeToSolveProblem.value);
      }
      let document_type = props?.isTemplate ? 'ProgramSchema' : 'Program';
      if (props?.isDraft) {
        document_type = 'ProgramRevision';
      }
      const response = await saveActivitySettings({
        document_type,
        document_id: programIdValue?.value?.toString(),
        adk_name: activityNameValue?.value,
        payload
      });
      if (response) {
        props.fetchProgram();
        setTimeout(() => {
          isLoading.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: response?.message,
            type: 'success',
            isShowSnackbar: true
          });
        }, 2000);
      }
    };

    const reminderRules = ref({
      reminder: value => (value && value < idealTime.value) || 'Required.'
    });

    return {
      dialog,
      programIdValue,
      activityNameValue,
      iconName,
      setupRequired,
      requirements,
      requirementOptions,
      requirementSearch,
      activityRequired,
      isDeliverable,
      outcomes,
      instructions,
      reminder,
      mongoDbChart,
      activityPlacement,
      themes,
      programTemplates,
      resourceTypes,
      activityGroups,
      activityView,
      postActivityReflection,
      makeActivityAccessible,
      allowToEndProgramEarly,
      merits,
      typeOfCharts,
      presetAndSetup,
      participantAndTeamData,
      saveActivitySettingsData,
      isLoading,
      idealTime,
      timeIn,
      timeOptions,
      idealActivityCompletionTime,
      timeInForCountDown,
      timeOptionsForCountDown,
      reminderRules,
      theme,
      credentials,
      rewards,
      CredentialListItems,
      activities,
      isPreview,
      timerType,
      isShowFeedBackForm,
      timeToSolveProblem,
      checkTimeToSolveProblem
    };
  }
});
